import { useStore } from "vuex";
import { useRouter } from "vue-router";

export const useNavigation = () => {
  const store = useStore();
  const router = useRouter();

  const smartBack = fallback => {
    router.push(smartBackTo(fallback));
  };

  const smartBackTo = fallback => {
    while (
      store.getters["Router/hasHistory"] &&
      store.getters["Router/lastInHistory"] &&
      (router.currentRoute.value.path === store.getters["Router/lastInHistory"].path ||
        (store.getters["Router/lastInHistory"].meta || {}).notInHistory == true)
    ) {
      store.commit("Router/historyPop");
    }
    if (store.getters["Router/lastInHistory"]) {
      return store.getters["Router/lastInHistory"].path;
    }
    if (fallback == null) {
      fallback = store.getters["User/roleHome"] || "/";
    }
    return fallback;
  };

  return {
    smartBack,
    smartBackTo
  };
};
