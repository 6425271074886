<template>
  <div class="icon" v-if="icon" :aria-label="icon">
    <svg viewBox="0 0 24 24" class="icon__svg-icon" :focusable="String(focusable)">
      <use :xlink:href="'#' + icon" />
    </svg>
  </div>
</template>

<script>
import("@/assets/icons/allRequiredIcons.js");

export default {
  name: "Icon",
  props: {
    icon: { type: String, default: "" },
    focusable: { type: Boolean, default: false }
  }
};
</script>

<style scoped lang="scss">
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
}
</style>
