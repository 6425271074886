<template>
  <div>
    <div class="common-backbar">
      <div class="back" @click="navigation.smartBack()"><Icon icon="arrow-left" /> back</div>
    </div>
    <div class="page">
      <h1>Terms of Service for Spotee Connect</h1>
      <p>Current as at 18 July 2021</p>

      <p>Please read this information carefully before you decide to proceed with our service.</p>

      <h2>Acceptance</h2>
      <p>
        By paying for this service and joining as a member, you are confirming that you accept the terms expressed in
        this document.
      </p>

      <h2>Applicability</h2>
      <p>
        These Terms apply to you from the published date, whether you are a new or existing member and whether you are
        viewing Spotee Connect on your own behalf, or on behalf of another person (such as your employer).
      </p>

      <h2>Who will be providing financial services to you?</h2>
      <p>Financial services will either be provided to you by Spotee’s directors and/or Spotee’s employees.</p>
      <p>
        Spotee Pty Ltd ABN 55 637 623 940 (Spotee) is a corporate authorised representative (CARN 001283189) of Daylight
        Financial Group Pty Ltd ABN 77 633 984 773. Daylight Financial Group Pty Ltd is the holder of an Australian
        Financial Services Licence, number 521404, and has authorised Spotee to provide financial services on its
        behalf.
      </p>
      <p>
        To the extent Spotee’s directors or employees provide financial services, the directors or employees act as
        representatives of Daylight Financial Group Pty Ltd.
      </p>

      <h2>General advice only</h2>
      <p>
        Any advice that we give in Spotee Connect is general financial advice and information that does not take into
        account your personal situation. You should therefore consider its appropriateness, in light of your objectives,
        financial situation or needs, before acting on it. Before making any investment decision, you should also
        consider obtaining personal financial, legal and taxation advice and read our
        <a href="https://spotee.com.au/financial-services-guide/" target="_blank">Financial Services Guide</a>. Do not
        make investment decisions based only on information from Spotee Connect.
      </p>

      <h2>Grant of licence</h2>
      <p>
        Spotee hereby grants you a limited, non-exclusive, non-transferable licence subject to the Terms included to use
        the products and services offered in Spotee Connect.
      </p>

      <h2>Licence is not transferable</h2>
      <p>
        You may not temporarily or permanently transfer or sub-license your rights to use Spotee Connect under the
        Terms, without the prior written consent of Spotee.
      </p>

      <h2>What does the Spotee Connect service entail?</h2>
      <p>
        Spotee Connect is a website service where members (“Spotters”) are able to ask a question (“shine the light”) on
        any stock on the Australian securities exchange (ASX).
      </p>
      <p>
        These questions are then responded to by one of our authorised Spotee staff members who will deliver their
        insights, on those businesses and any other relevant information for a client to consider.
      </p>
      <p>
        These responses are then also visible by other users of the software. This allows the broader Spotee Connect
        community to benefit from the responses to these questions.
      </p>
      <p>
        All questions and answers are completed within the Spotee Connect program. There is no Q&A service provided via
        telephone, email or other communication platform.
      </p>

      <h2>What purpose does the Spotee Connect service serve?</h2>
      <p>
        Spotee Connect is about connecting individuals with a market expert who is suitably qualified to make a comment
        about a stock.
      </p>
      <p>
        Having the ability to validate your thoughts with a professional before making a decision is a service that
        normal is only available to those with a sizeable investment amount and pre-existing relationships. We seek to
        make such insights available to a broader cross-section of the community making DIY investing more accessible.
      </p>
      <p>
        This service differs greatly from a chat forum, where investors may often receive responses from anonymous
        individuals without knowledge of their qualifications or objectives. Within Spotee Connect, you still receive
        the benefit of being part of, and learning from, a community of like-minded investors, however all answers are
        delivered by our team of qualified experts. Our goal is to help you identify where the current potential
        opportunities and/or risks lie within a business so that you make more informed investment decisions.
      </p>
      <p>
        Further, Spotee Connect’s service is research provider agnostic. Meaning we compliment all current research
        services available on the market today, by providing you with an objective view to help support your investment
        decision making process, no matter what style of investing you wish to apply.
      </p>
      <p>
        It is important to note that we are not a source of primary research. Rather we act as a qualifier to something
        you have read, heard or received from either a third party research provider and/or media source.
      </p>
      <p>
        All responses within Spotee Connect, even those made to specific questions, are prepared without considering
        anyone’s personal objectives, situation or needs. Therefore, should you wish to discuss any response considering
        your personal circumstances then you need to engage with a financial advisor licenced to have that discussion
        with you.
      </p>

      <h2>Be sure to read our Financial Services Guide (FSG)</h2>
      <p>
        This is an important document that highlights our obligations to you and to the law. Read the document here:
        <a href="https://spotee.com.au/financial-services-guide/" target="_blank"
          >https://spotee.com.au/financial-services-guide/</a
        >
      </p>
      <p>By subscribing to this service, you are confirming that you have read the document.</p>

      <h2>What charges will you incur for the Spotee Connect service?</h2>
      <p>
        The ongoing fee is $29.95 per month (inc GST). This is a pay-by-the-month service where:
      </p>
      <ul>
        <li>We will periodically debit your monthly fee from your specified card.</li>
        <li>
          The first drawing will be on processing of your Spotee Connect registration form (either completed online or
          via paper form).
        </li>
        <li>Subsequent amounts will be debited the same date each month until you cancel your membership.</li>
        <li>You can cancel at any time, with your access continuing until the end of the subscription period.</li>
        <li>There are strictly no refunds</li>
      </ul>
      <p>
        This per month charge does not include anything outside of the Spotee Connect offering. Spotee Pty Ltd may offer
        other services which may carry additional fees if you wish to access them.
      </p>
      <p>
        This charge does not include the services for any third-party membership or software that you may purchase to
        help assist you with your investing. Spotee Pty Ltd acts independently of all third-party providers and you must
        accept their relevant terms and conditions.
      </p>
      <p>
        We take no responsibility for their actions or the outcome of their service. Their obligations are strictly and
        only limited to you as a client and any responsibility to accept their services are yours.
      </p>

      <h2>General investment warning</h2>
      <p>The value of an investment may go down as well as up.</p>
      <p>
        It is important you understand that past performance of an investment is not a reliable indicator of future
        performance. We do not guarantee the return of capital.
      </p>
      <p>
        If you choose to use information in this service as the basis for an investment decision, Daylight Financial
        Group, Spotee or any other company in the Daylight Financial Group and the officers, employees and agents of
        these entities bear no responsibility for the outcome of that decision.
      </p>
      <p>
        The information we provide will always be of a general nature only and does not take into account any
        individual’s objectives, financial situation or needs. Therefore, before acting on any advice, you should
        consider the appropriateness of the advice, having regard to your objectives, financial situation, and needs.
      </p>

      <h2>Spotee rights</h2>
      <p>
        You acknowledge that the rights to the intellectual property (IP) provided via your licence are the sole and
        exclusive property of Spotee. By accepting these Terms, you do not become the owner of the IP, but you do have
        the right to use the IP in accordance with these Terms. You agree to use your best efforts and to take all
        reasonable steps to protect the IP from unauthorised use, illegal reproduction, or illicit distribution.
      </p>

      <h2>Liability</h2>
      <p>
        Subject to your statutory rights, you agree that, in deciding to become or remain a Spotee Connect member, you
        have not relied in any way on our representations, descriptions, illustrations, specifications, skill or
        judgment and that you have satisfied yourself as to the condition and suitability of Spotee Connect for you.
        Daylight Financial Group, Spotee or any other company in the Daylight Financial Group and the officers,
        employees and agents of these entities give no guarantee, warranty, undertaking or representation in relation to
        the condition, accuracy, suitability, quality of or title to Spotee Connect (including any data contained in or
        supplied in relation to it, or reports generated, or produced by, or with the aid of it). All implied
        guarantees, warranties and liabilities (including liability as to negligence) in relation to same are negated
        and excluded.
      </p>
      <p>
        This disclaimer does not exclude any liability, warranties implied, or rights conferred, by law that may not be
        lawfully excluded.
      </p>
      <p>
        Except as provided by consumer protection law, under no circumstances will, Daylight Financial Group, Spotee or
        any other company in the Daylight Financial Group and its related companies be liable for any loss or damage
        caused by a client’s use or access to any commentary, either written or implied. This includes the use of Spotee
        information delivered to you by a third party financial service provider (e.g. advisers). You should refer to
        the FSG of your other financial services provider for further information about the services they offer.
      </p>
      <p>
        Subject to any statutory rights you might have, you agree that Daylight Financial Group, Spotee or any other
        company in the Daylight Financial Group are not liable for any loss or damage, including consequential loss or
        damage, that in any way results from your use or non-use of Spotee Connect (including as a result of any
        malfunction, breakdown, error or virus in Spotee Connect), or as a result of, or in connection with, the
        provision or non-provision of services under these Terms.
      </p>
      <p>
        Subject to any contrary statutory provisions, you agree to indemnify us and keep us indemnified against: (a) all
        and any demands, claims, actions and proceedings whatsoever and howsoever arising made by any third person in
        connection with or arising out of your use of Spotee Connect; and (b) all and any losses, costs, expenses and
        damages whatsoever and howsoever incurred by Spotee in connection with, or arising out of a breach by you of any
        provision of this document.
      </p>
      <p>
        Nothing in these Terms excludes, restricts, or modifies any condition, warranty, guarantee, right or remedy
        conferred on you by statute that we are not lawfully able to exclude, restrict or modify. If we breach a
        non-excludable condition, guarantee or warranty imposed by statute, our liability for breach will be limited, to
        the extent lawfully permissible, to: (a) for the supply of goods – the replacement of the goods or the supply of
        equivalent goods, the repair of the goods, the payment of the cost of replacing the goods or of acquiring
        equivalent materials, or the payment of the cost of having the goods repaired; (b) for the provision of services
        – supplying the services again, or paying the cost of having the services supplied again; whichever we see fit
        to provide.
      </p>

      <h2>Confidentiality of login details</h2>
      <p>
        To use Spotee Connect, you need a valid email and password. You agree to:
      </p>
      <ul>
        <li>Treat these as confidential</li>
        <li>Not disclose them to anyone</li>
        <li>
          Take reasonable measures to prevent your email and password being used for unauthorised access to Spotee
          Connect.
        </li>
      </ul>
      <p>
        Unless you notify us that the confidentiality of your password has been compromised, we will construe all
        activity on Spotee Connect using your password as being for, and on your behalf. You are responsible for all
        actions of others using your password.
      </p>

      <h2>Availability of the Spotee Connect website</h2>
      <p>
        We will endeavour to make Spotee Connect available 24 hours a day, 7 days a week, except for:
      </p>
      <ul>
        <li>Any planned downtime is normally undertaken on weekday mornings.</li>
        <li>
          Things outside our control, such as an interruption to internet services to you, to us, or to another entity
          through which Spotee Connect is administered, accessed or hosted, an Act of God, flood, fire, earthquake,
          civil unrest, Act of Terror, a fault in or delay by another telecommunications network that Spotee Connect is
          connected to, a strike, or other labour problem (other than one involving our employees), or cyber-attacks
          such as a denial of service attack.
        </li>
      </ul>

      <h2>Our own disclosure</h2>
      <p>
        Spotee, Daylight Financial Group Pty Ltd, any employees and/or associates of these entities, may hold interests
        in ASX-listed companies referred to on this website. Further information about particular stocks held by these
        entities, or persons from time to time, is disclosed within Spotee Connect and may change at any time without
        notice.
      </p>

      <h2>Privacy policy</h2>
      <p>
        We are committed to preserving and respecting your privacy and complying with the Privacy Act 1988. For full
        details about our privacy practices and your rights, see the Daylight Financial Group’s Privacy policy and
        collection notice at www.daylightfinancial.com.au or www.spotee.com.au .
      </p>

      <h2>Amendment</h2>
      <p>
        We can change information provided on or by Spotee Connect, including these Terms, at any time without notice by
        publishing updated Terms on the Spotee Connect section of the website. By accessing and using Spotee Connect,
        you agree to regularly review these Terms for changes. When renewing your membership, you will be asked to
        acknowledge theses terms and conditions and our Financial Services Guide so that you are aware of any changes.
      </p>

      <h2>Dispute Resolution</h2>
      <p>
        If you have any complaints about the service that we provide, please take the following steps:
      </p>
      <ul>
        <li>
          First, contact the team at Spotee by email at enquiries@spotee.com.au. We will endeavour to resolve any client
          complaint or query as soon as possible.
        </li>
        <li>
          If you prefer, you can lodge your complaint in writing to: Managing Director, Spotee Pty Ltd, PO Box 333
          Avondale Heights, VIC 3034.
        </li>
        <li>
          If you do not receive a satisfactory outcome, you have the right to complain to the Australian Financial
          Complaints Authority. The contact details for the Australian Financial Complaints Authority are GPO Box 3,
          Melbourne, Victoria, 3001 by phone 1800 931 678 (free call), by email at info@afca.org.au or fax (03) 9613
          6399.
        </li>
        <li>
          The Australian Securities and Investments Commission (ASIC) also has an Info line number 1300 300 630 which
          you may use to make a complaint and obtain information about your rights.
        </li>
      </ul>

      <h2>Enquiries</h2>

      <p>If you have any queries, please email Spotee at enquiries@spotee.com.au</p>

      <p>Copyright © 2021 Spotee Pty Ltd. All rights reserved</p>
    </div>
    <div class="common-backbar">
      <div class="back" @click="navigation.smartBack()"><Icon icon="arrow-left" /> back</div>
    </div>
  </div>
</template>

<script>
import { useNavigation } from "@/components/renderless/navigation";
import Icon from "@/components/atoms/Icon.vue";
export default {
  name: "Terms",
  components: {
    Icon
  },
  setup() {
    const navigation = useNavigation();

    return {
      navigation
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.page {
  @include flexCentered(column);
  @include golfBackground;
  @include maxWidth($size-breakpoint-desktop);
  padding: addSpace(4);
  text-align: justify;

  h2 {
    font-weight: 300;
    color: $color-blue;
  }
}
</style>
